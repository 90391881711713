import styled from "@emotion/styled";
import { MenuList, Popper } from "@mui/material";
import em from "src/utils/em";

export const Base = styled.div`
  position: relative;
`;

export const BasePopper = styled(Popper)`
  z-index: 500;
`;

export const BaseMenu = styled(MenuList)`
  min-width: ${em(150)};
  padding-top: 0;
  padding-bottom: 0;
`;
