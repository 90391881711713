export const ACCOUNT = "account";
export const ARTIST_ROSTER = "artistRoster";
export const CHANNELS = "channels";
export const COMMON = "common";
export const COMMUNITY_CASTING = "communityCasting";
export const DIRECTORY = "directory";
export const EMAILS = "emails";
export const GODMODE = "godMode";
export const LISTS = "lists";
export const PROFILE = "profile";
export const PROJECTS = "projects";
export const REGISTRATION = "registration";
export const ROLES = "roles";
export const SESSIONS = "sessions";
export const STATUS_REPORT = "statusReport";
export const VALIDATIONS = "validations";
