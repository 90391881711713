import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { alpha, borderColor } from "@mui/system";
import em from "src/utils/em";

import COLORS, { toImportant } from "./colors";

const theme = createTheme({
  app: {
    maxWidth: 1850,
    navWidthXS: 260,
    navWidthMD: 224,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 950,
      lg: 1200,
      xl: 1536,
    },
  },
  spacing: (factor: number) => `${0.5 * factor}em`,
  typography: {
    fontFamily: ["Arial", "sans-serif"].join(","),
    h1: {
      fontWeight: 700,
      fontSize: 48,
      lineHeight: 1.2,
      letterSpacing: -0.8,
    },
    h2: {
      fontWeight: 700,
      fontSize: 36,
      lineHeight: 1.2,
      letterSpacing: -0.8,
    },
    h3: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.2,
      letterSpacing: -0.8,
    },
    h4: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 1.2,
      letterSpacing: -0.8,
    },
    h5: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: 12,
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
      letterSpacing: -0.2,
      fontSize: 16,
    },
    body2: {
      fontWeight: 300,
      letterSpacing: -0.2,
      fontSize: 16,
    },
    caption: {
      fontWeight: 500,
      fontSize: 14,
    },
    button: {
      fontWeight: 400,
      fontSize: 18,
      letterSpacing: -0.8,
    },
  },
  palette: {
    error: {
      main: toImportant(COLORS.ERROR),
    },
    success: {
      main: toImportant(COLORS.SUCCESS),
    },
    warning: {
      main: COLORS.DANGER,
    },
    background: {
      default: COLORS.BACKGROUND,
      paper: COLORS.PAPER,
    },
    primary: {
      dark: COLORS.TERTIARY,
      main: COLORS.PRIMARY,
      light: COLORS.QUATERNARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
      light: COLORS.QUINARY,
    },
    text: {
      primary: COLORS.PRIMARY,
      secondary: COLORS.SECONDARY,
    },
    divider: grey[200],
    recording: {
      main: COLORS.RECORDING,
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: em(12),
          letterSpacing: 0,
          padding: em(12),
          width: "100%",
          maxWidth: em(220),
          borderRadius: em(32),
        },
        contained: {
          fontWeight: 700,
          ":disabled": {
            color: alpha(COLORS.BACKGROUND_DARK, 0.8),
            backgroundColor: alpha(COLORS.QUATERNARY, 0.2),
          },
          '&[aria-disabled="true"]': {
            color: alpha(COLORS.BACKGROUND_DARK, 0.8),
            backgroundColor: alpha(COLORS.SECONDARY, 0.2),
          },
        },
        outlined: {
          borderWidth: 2,
          "&:hover": {
            borderWidth: 2,
          },
          ":disabled": {
            borderWidth: 2,
            color: alpha(COLORS.QUATERNARY, 0.5),
            borderColor: alpha(COLORS.QUATERNARY, 0.2),
          },
          '&[aria-disabled="true"]': {
            borderWidth: 2,
            color: alpha(COLORS.QUATERNARY, 0.5),
            borderColor: alpha(COLORS.QUATERNARY, 0.2),
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {},
        groupLabel: {
          color: alpha(COLORS.PRIMARY, 0.8),
          fontWeight: 200,
          fontSize: em(16),
        },
        listbox: {
          backgroundColor: COLORS.TERTIARY,
        },
        option: {
          paddingTop: `${em(16)}!important`,
          paddingBottom: `${em(16)}!important`,
          "&.Mui-focused": {
            backgroundColor: toImportant(COLORS.BACKGROUND_LIGHT),
          },
        },
        noOptions: {
          color: alpha(COLORS.PRIMARY, 0.5),
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.TERTIARY,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          color: toImportant(alpha(COLORS.PRIMARY, 0.5)),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: grey[300],
          ":disabled": {
            color: toImportant(alpha(grey[300], 0.5)),
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: alpha(COLORS.PRIMARY, 0.7),
          marginLeft: 0,
          marginTop: em(8),
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.TERTIARY,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {},
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled input": {
            WebkitTextFillColor: toImportant(alpha(grey[300], 0.3)),
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: toImportant(alpha(grey[300], 0.3)),
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: grey[300],
          "&.Mui-disabled": {
            color: toImportant(alpha(grey[300], 0.5)),
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: grey[300],
          "&.Mui-active": {
            color: grey[300],
          },
          ":hover": {
            color: grey[300],
          },
          ":focus": {
            color: grey[300],
          },
        },
        icon: {
          color: grey[300],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&.Mui-disabled": {
            textFillColor: toImportant(alpha(grey[300], 0.5)),
            border: `1px solid ${alpha(grey[300], 0.3)}`,
          },
        },
        icon: {
          color: COLORS.PRIMARY,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.BACKGROUND,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: em(16),
          paddingBottom: em(16),
          "&:hover, &:focus": {
            backgroundColor: COLORS.BACKGROUND_LIGHT,
          },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          paddingTop: em(14),
          paddingBottom: em(14),
          paddingLeft: em(13),
          paddingRight: em(13),
          borderColor: grey[500],
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: em(4),
          "&:hover": {
            borderColor: COLORS.PRIMARY,
          },
          "&:focus-within": {
            outline: `1px solid ${COLORS.PRIMARY}`,
          },
          "&.Mui-focused": {
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: COLORS.PRIMARY,
          },
          "&.Mui-disabled": {
            borderColor: toImportant(alpha(grey[500], 0.5)),
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: alpha(COLORS.PRIMARY, 0.5),
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
        notchedOutline: {
          borderColor: grey[500],
        },
      },
    },
  },
});

export default theme;
