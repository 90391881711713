// Helper to simplify theme references wthin styles

import { Theme } from "@mui/material";

const space =
  (v: number = 2) =>
  ({ theme }: { theme: Theme }): string =>
    theme.spacing(v);

export default space;
