import { drop } from "ramda";
import { NavItem } from "src/types/NavItem";
import toNavPathName from "./toNavPathName";
import toI18nContextString from "src/utils/toI18nContextString";

// Simple string append
const toAppendBaseUrl = (acc: string, v: NavItem) => `${acc}/${v.id}`;

const toAppendPath = (list: NavItem[], path: string): string => {
  // if root, or primary nav return original path
  if (list.length < 2) return `/${path}`;
  // Drop root and primary nav from the list
  const secondaryNavList = drop(1, list);
  // Generate the full path from parent routes
  const basePath = secondaryNavList.reduce<string>(toAppendBaseUrl, "");
  // Append current path
  return `${basePath}/${path}`;
};

// Generate nav items
const toBreadcrumb = (
  acc: NavItem[],
  path: string,
  index: number
): NavItem[] => {
  // Home path, is not defined in the URL, root path. This needs to be defined.
  if (path.length === 0) {
    return [{ id: "home", labelKey: "home", href: toAppendPath(acc, path) }];
  }

  // Remove queryString parameters from breadcrumb and links
  if (path.indexOf("?") !== -1) {
    path = path.slice(0, path.indexOf("?"));
  }
  // Also remove hashes
  if (path.indexOf("#") !== -1) {
    path = path.slice(0, path.indexOf("#"));
  }

  // All other routes are inferred by path name
  return [
    ...acc,
    {
      id: path,
      labelKey: toI18nContextString(toNavPathName(path)),
      pathLabel: toNavPathName(path),
      href: toAppendPath(acc, path),
    },
  ];
};

export default toBreadcrumb;
