import { format } from "date-fns";
import moment from "moment-timezone";

const formatSelftapeDueDate = (
  date: Date,
  timezone: string,
  time?: Date
): string => {
  let dateString = format(date, "yyyy-MM-dd");
  if (time) {
    const timeString = format(time, " HH:mm");
    dateString = `${dateString}${time ? timeString : ""}`;
  }

  return moment.utc(moment.tz(dateString, timezone)).format();
};

export default formatSelftapeDueDate;
