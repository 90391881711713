import axios, { AxiosInstance } from "axios";

// TODO - this will no longer be necessary once we have all our microservices behind one load-balancer
export interface ServiceVariant {
  variant:
    | "projects"
    | "roles"
    | "directory"
    | "users"
    | "external"
    | "ipApi"
    | "ipInfo";
}

export class HTTPAnonymousService {
  protected projectsRequest = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_ALTAI_PROJECTS_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      // Never want to cache api responses, things can get very strange
      "Cache-Control": "no-store",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  protected rolesRequest = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_ALTAI_ROLES_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      // Never want to cache api responses, things can get very strange
      "Cache-Control": "no-store",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  protected directoryRequest = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_ALTAI_DIRECTORY_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      // Never want to cache api responses, things can get very strange
      "Cache-Control": "no-store",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  protected usersRequest = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_ALTAI_USERS_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      // Never want to cache api responses, things can get very strange
      "Cache-Control": "no-store",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  protected externalRequest = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_ALTAI_EXTERNAL_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      // Never want to cache api responses, things can get very strange
      "Cache-Control": "no-store",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  protected ipApiRequest = axios.create({
    baseURL: "http://ip-api.com/",
    headers: {
      "Content-Type": "application/json",
      // Never want to cache api responses, things can get very strange
      "Cache-Control": "no-store",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  protected ipInfoRequest = axios.create({
    baseURL: "https://ipinfo.io",
    headers: {
      "Content-Type": "application/json",
      // Never want to cache api responses, things can get very strange
      "Cache-Control": "no-store",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

  public toRequest = (variant: ServiceVariant["variant"]): AxiosInstance => {
    switch (variant) {
      case "projects":
        return this.projectsRequest;
      case "roles":
        return this.rolesRequest;
      case "directory":
        return this.directoryRequest;
      case "users":
        return this.usersRequest;
      case "external":
        return this.externalRequest;
      case "ipApi":
        return this.ipApiRequest;
      case "ipInfo":
        return this.ipInfoRequest;
      default:
        throw Error("Invalid variant");
    }
  };
}
