import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from "@microsoft/signalr";
import SubmissionsPushType from "./entities/SubmissionsPushType";
import SubmissionPush from "./entities/SubmissionPush";
import BadgeCount from "./entities/BadgeCounts";

export type SubmissionsEventType =
  | "Created"
  | "Deleted"
  | "Updated"
  | "AllMarkedReviewed";

// Event type
export type WebSocketEvent = {
  type: SubmissionsEventType;
  data: any;
  reviewedSubmissionIds: string[] | null;
  newRoleId: string | null;
  badgeCount: BadgeCount;
};

export type SubscriberItem = {
  id: string;
  callback: (event: WebSocketEvent) => void;
};

class WebSocketSubmissionService {
  /********************************************** */
  // vars
  /********************************************** */
  wsClient: HubConnection | null = null;
  url: string | null = "";
  subscribers: SubscriberItem[] = [];

  /********************************************** */
  // Constructor
  /********************************************** */
  constructor(url: string) {
    this.url = url;
  }

  /********************************************************************** */
  // Private
  /********************************************************************** */

  /********************************************** */
  // Transform response
  /********************************************** */
  private _toPushData(data: any): SubmissionPush | null {
    if (data === null) return null;
    return {
      type: data?.type ?? SubmissionsPushType.Updated,
      data: data?.data ?? null,
      reviewedSubmissionIds: data?.reviewedSubmissionIds ?? null,
      newRoleId: data?.newRoleId ?? null,
      badgeCount: {
        inReview: data?.badgeCount?.toReviewCount ?? 0,
        reviewed: data?.badgeCount?.reviewedCount ?? 0,
        selected: data?.badgeCount?.selectedCount ?? 0,
      },
    };
  }

  /********************************************** */
  // Determine event
  /********************************************** */
  private _toSubmissionEvent(type: SubmissionsPushType): SubmissionsEventType {
    switch (type) {
      case SubmissionsPushType.Updated:
        return "Updated";
      case SubmissionsPushType.Created:
        return "Created";
      case SubmissionsPushType.Deleted:
        return "Deleted";
      case SubmissionsPushType.AllMarkedReviewed:
        return "AllMarkedReviewed";
    }
  }

  /********************************************** */
  // Initiate connection
  /********************************************** */

  private _init(url: string): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();
  }

  /********************************************** */
  // Event - Initial connection
  /********************************************** */
  private _onIndividualConnectionHandler = () => {
    console.log("Connected");
  };

  /********************************************** */
  // Event - Data update
  /********************************************** */
  private _onReceiveDataHandler =
    (callback: (event: WebSocketEvent) => void) => (data: any) => {
      const pushData = this._toPushData(data);
      if (pushData === null) return;
      const pushType = this._toSubmissionEvent(pushData.type);

      // Notify subscribers
      callback({
        ...pushData,
        type: pushType,
      });
      // this._notifySubscribers({ ...pushData, type: pushType });
    };

  /********************************************** */
  // Event - Connection closed
  /********************************************** */
  private _onCloseHandler = (error: any) => {};

  private _toPath(url: string, roleId: string): string {
    return url.replace("[roleId]", roleId);
  }

  /********************************************************************** */
  // Public
  /********************************************************************** */

  /********************************************** */
  // Initiate connection
  /********************************************** */
  public async connect(
    roleId: string,
    callback: (evt: WebSocketEvent) => void
  ): Promise<void> {
    // Check dependencies
    if (typeof roleId !== "string") {
      throw new Error("roleId must be a string");
    }
    if (this.url === "") {
      throw new Error("No url provided");
    }

    // Initialise connection
    const path = this._toPath(this.url!, roleId);
    this.wsClient = this._init(path);
    this.wsClient.on(
      "onIndividualConnection",
      this._onIndividualConnectionHandler
    );
    this.wsClient.on("receiveData", this._onReceiveDataHandler(callback));
    this.wsClient.onclose(this._onCloseHandler);

    // Initiate connection
    await this.wsClient.start();
  }

  /********************************************** */
  // Disconnect
  /********************************************** */
  public async disconnect(
    callback: (evt: WebSocketEvent) => void
  ): Promise<void> {
    if (this.wsClient === null) return;

    // Garbage collection
    this.wsClient.off(
      "onIndividualConnection",
      this._onIndividualConnectionHandler
    );
    this.wsClient.off("receiveData", this._onReceiveDataHandler(callback));

    // Close connection
    await this.wsClient.stop();
    this.wsClient = null;
  }
}

export default WebSocketSubmissionService;
