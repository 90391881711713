import { NavItem } from "src/types/NavItem";

const basePrimaryRoutes: NavItem[] = [
  {
    id: "projects",
    labelKey: "projects",
    href: "/projects",
  },
  {
    id: "calendar",
    labelKey: "calendar",
    href: "/calendar",
  },
  {
    id: "directory",
    labelKey: "directory",
    href: "/directory",
  },
  {
    id: "lists",
    labelKey: "lists",
    href: "/lists",
  },
];

export default basePrimaryRoutes;
