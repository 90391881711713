// check if Date is defined, else return null;
const toDate = (date: string | undefined): Date | null => {
  if (!date) return null;
  // Fix for dotnet not returning the "Z" to signify UTC time
  if (!date.endsWith("Z")) {
    date += "Z";
  }
  return new Date(date);
};

export default toDate;
