import { AutocompleteItem } from "src/components/Autocomplete";

// Need to account for the fact that the values are not always strings
// E.g. initial values, which then are updated by auto-complete into key-pair values
// Issue is linked to the `useEffect` setValue function
const toAutocompleteItem = (
  value: string | AutocompleteItem
): AutocompleteItem => {
  if (typeof value !== "string") return value;
  return {
    key: value,
    value: value.toLowerCase(),
  };
};

export default toAutocompleteItem;
