const COLORS = {
  PRIMARY: "#ffffff",
  SECONDARY: "#F4D7C6",
  TERTIARY: "#2E2E2E",
  QUATERNARY: "#F7F4EF",
  QUINARY: "#828B88",

  //
  ERROR: "#d32f2f",
  SUCCESS: "rgba(40, 167, 69, 1)",
  BACKGROUND: "#222222",
  BACKGROUND_LIGHT: "#3F3F3F",
  BACKGROUND_DARK: "#1e1e1e",
  PAPER: "rgba(0, 0, 0, 0.4)",
  DANGER: "#DC3545",
  RECORDING: "#A82F27",
};

export const toImportant = (color: string) => `${color}!important`;

export default COLORS;
