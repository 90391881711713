import { createContext, ReactNode, useContext } from "react";
import type { InterpreterFrom } from "xstate";
import { useInterpret } from "@xstate/react";
import appMachine from "src/machines/appMachine";
import { useRouter } from "next/router";

interface AppProviderProps {
  children: ReactNode;
}

interface AppStateContextType {
  appService: InterpreterFrom<typeof appMachine>;
}

const AppContext = createContext({} as AppStateContextType);

const AppProvider = ({ children }: AppProviderProps) => {
  // TODO - Assume there should be only one service remove the service name and make it a type, similar to ProjectContext
  const router = useRouter();

  const service = useInterpret(appMachine, {
    devTools: true,
    context: {
      routerPath: router.pathname,
    },
    actions: {
      showAccountPaused: () => {
        router.push({ pathname: "/account-paused" });
      },
      showArtistPricingTable: () => {
        router.push({ pathname: "/pricing-table" });
      },
    },
  });

  return (
    <AppContext.Provider value={{ appService: service }}>
      {children}
    </AppContext.Provider>
  );
};

const useApp = () => {
  const context = useContext(AppContext);
  if (typeof context === "undefined") {
    throw new Error("useApp must be used within an AppProvider");
  }
  return context;
};

export { useApp, AppProvider };
