import { NotificationContext, NotificationEvents } from "..";

export const notifyInitiated = (
  ctx: NotificationContext,
  evt: NotificationEvents
) => {
  console.log("ACTION(notifyInitiated): placeholder");
};

export const notifyMarkAllReadComplete = () => {
  console.log("ACTION(notifyMarkAllReadComplete): placeholder");
};
