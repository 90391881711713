import { createMachine } from "xstate";

import context from "./context";
import * as actions from "./actions";
import * as guards from "./guards";

import { NavItem } from "src/types/NavItem";
import { MenuOption } from "src/components/HeaderBarMenu";

export type NavigationEvent =
  | { type: "PONG_POSITION_UPDATE"; pos: number }
  | { type: "PONG_ACTIVE_POSITION"; path: string }
  | { type: "UPDATE"; path: string }
  | { type: "HEADER_MENU_UPDATE"; id: MenuOption["id"] }
  | { type: "ACTIVE" }
  | { type: "INACTIVE" }
  | { type: "SECONDARY_NAV_INIT" }
  | {
      type: "SET_ACTIVE_ROUTE";
      active: NavItem;
    }
  | {
      type: "SECONDARY_NAV_READY";
      root: NavItem | null;
      list: NavItem[];
      breadcrumbReplace: PathNameReplace[];
    }
  | { type: "SET_PRIMARY_NAV"; list: NavItem[]; menuOptions: MenuOption[] }
  | { type: "UPDATE_BREADCRUMB"; breadcrumbReplace: PathNameReplace[] };

// Use to replace breadcrumb paths with new name
export interface PathNameReplace {
  original: string;
  replacement: string;
}

export const NAVIGATION_TAGS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

export interface NavigationContext {
  active?: boolean;
  version: string;
  path: string;
  headerMenu: MenuOption[];
  breadcrumb: {
    list: NavItem[];
    pathNameReplace: PathNameReplace[];
  };
  primary: {
    pongActive: boolean;
    posY: number;
    active: NavItem;
    list: NavItem[];
  };
  secondary: {
    ready: boolean;
    pongActive: boolean;
    posY: number;
    root: NavItem | null;
    list: NavItem[];
  };
}

// Core business logic for the following, (create separate to re-use for both states)
// - Set pong element active or inactive
// - Set pong position
const pongState = {
  initial: "idle",
  states: {
    idle: {
      on: {
        PONG_ACTIVE_POSITION: { actions: ["setPongActive"] },
        PONG_POSITION_UPDATE: { actions: ["setPosition"] },
      },
    },
  },
};

const navigationMachine = createMachine<NavigationContext, NavigationEvent>(
  {
    id: "navigation",
    initial: "init",
    context,
    states: {
      init: {},
      primary: {
        tags: [NAVIGATION_TAGS.PRIMARY],
        entry: [
          "clearSecondaryNav",
          "setPath",
          "setBreadcrumb",
          "notifyPrimary",
        ],
        ...pongState,
        on: {
          ACTIVE: { actions: ["setActive"] },
          INACTIVE: { actions: ["setInactive"] },
        },
      },
      // Each trigger that occurs after page load
      secondary: {
        tags: [NAVIGATION_TAGS.SECONDARY],
        entry: ["setPath", "setBreadcrumb"],
        ...pongState,
        on: {
          ACTIVE: { actions: ["setActive"] },
          INACTIVE: { actions: ["setInactive"] },
        },
      },
    },
    on: {
      HEADER_MENU_UPDATE: { actions: ["notifyHeaderMenu"] },
      // Handle loading of data to determine breadcrumb, replaces GUI from URL path with project name
      SECONDARY_NAV_INIT: {
        actions: ["setSecondaryNavNotReady"],
      },
      // Once data has loaded, update secondary nav, breadcrumb and flag with UI nav is ready
      SECONDARY_NAV_READY: {
        actions: ["setSecondaryNav", "setBreadcrumb", "setSecondaryNavReady", "notifyPrimary"],
      },
      UPDATE_BREADCRUMB: {
        actions: ["updateBreadcrumb"],
      },
      UPDATE: [
        {
          target: "primary",
          cond: "isPrimaryRoute",
        },
        {
          target: "secondary",
        },
      ],
      SET_ACTIVE_ROUTE: {
        actions: ["setActiveRoute"],
      },
      SET_PRIMARY_NAV: {
        actions: ["setPrimaryNav"],
      },
    },
  },
  {
    actions,
    guards,
  }
);

export type NavigationMachine = typeof navigationMachine;

export default navigationMachine;
