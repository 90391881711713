const toTitleCaseString = (value: string | null): string | null => {
  if (value === null || !value) return value;
  const words = value.split(" ");
  for (let i = 0; i < words.length; i++) {
    // Replace the first character with an upper case version, leave the rest of the string as-is
    words[i] =
      words[i].charAt(0).toUpperCase() +
      (words[i].length > 1 ? words[i].substring(1) : "");
  }

  return words.join(" ");
};

export default toTitleCaseString;
