import { NavItem } from "src/types/NavItem";

const adminPrimaryRoutes: NavItem[] = [
  {
    id: "approvals",
    labelKey: "approvals",
    href: "/admin/approvals",
  },
  {
    id: "bulkImport",
    labelKey: "bulkImport",
    href: "/bulk-import",
  },
  {
    id: "data",
    labelKey: "data",
    href: "/data",
  },
  {
    id: "directory",
    labelKey: "directory",
    href: "/directory",
  },
  {
    id: "comms",
    labelKey: "comms",
    href: "/comms",
  },
];

export default adminPrimaryRoutes;
