import { enAU, enUS, es, fr, ko } from "date-fns/locale";

export const toFnsLocale = (resolvedLocale?: string): Locale => {
  switch (resolvedLocale) {
    case "en-AU":
      return enAU;
    case "fr":
      return fr;
    case "es-MX":
    case "es":
      return es;
    case "ko-KR":
      return ko;
    default:
      return enUS;
  }
};
