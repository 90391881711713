import * as yup from "yup";

export interface ForgotPasswordProps {
  email: string;
}

export const initialValues: ForgotPasswordProps = {
  email: "",
};

const schema = yup.object({
  email: yup
    .string()
    .trim()
    .email("emailMustBeValidEmail")
    .required("emailRequired"),
});

export default schema;
