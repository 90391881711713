import styled from "@emotion/styled";
import space from "src/utils/space";
import mq from "src/utils/mq";
import em from "src/utils/em";

export const Base = styled.div`
  position: relative;
  padding: ${space()} 0 ${space(1)} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mq("sm")} {
    padding: ${space()} ${space()} ${space(1)} ${space()};
  }
`;

export const BaseGroup = styled.div`
  position: relative;
  display: flex;
`;

interface BaseLeadingProps {
  disableHamburger?: boolean;
}

export const BaseLeading = styled.div<BaseLeadingProps>`
  position: relative;
  width: ${({ theme }) => em(theme.app.navWidthXS)};
  padding-left: ${({ disableHamburger }) =>
    disableHamburger ? "0" : space(6.5)};
  display: flex;
  ${mq("md")} {
    padding-left: 0;
    width: ${({ theme }) => em(theme.app.navWidthMD)};
  }
`;

export const BaseMenu = styled.div`
  position: absolute;
  left: -0.6em;
  z-index: 100;
  /* Hide option on larger screens  */
  ${mq("md")} {
    display: none;
  }
`;

interface BaseBodyProps {
  active?: boolean;
}

export const BaseBody = styled.div<BaseBodyProps>`
  position: relative;
  display: none;
  opacity: ${({ active }) => (active ? 1 : 0.0)};
  ${mq("md")} {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`;

export const BaseActions = styled.div`
  position: relative;
  display: flex;
  gap: ${space(2)};
`;
