import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  AppInsightsContext,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { ReactNode } from "react";

const defaultBrowserHistory = {
  url: "/",
  location: { pathname: "" },
  listen: () => {},
};

let browserHistory = defaultBrowserHistory;
if (typeof window !== "undefined") {
  browserHistory = { ...browserHistory, ...window.history };
  browserHistory.location.pathname = browserHistory?.url;
}

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: `${
      process.env.NEXT_PUBLIC_APPLICATION_INSIGHTS_CONNECTION_STRING ??
      "InstrumentationKey=00000000-0000-0000-0000-000000000000"
    }`,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();

interface ApplicationInsightsProviderProps {
  children: ReactNode;
}

const ApplicationInsightsProvider = ({
  children,
}: ApplicationInsightsProviderProps) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export default ApplicationInsightsProvider;
