/********************************************** */
// Helper
/********************************************** */

const generateList = (obj: object, amount: number) => {
  const list = Array.from(Array(amount).keys());
  return list.map((_, index) => ({
    ...obj,
    id: `fake-${index}`,
  }));
};

export default generateList;
