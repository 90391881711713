import basePrimaryRoutes from "src/constants/basePrimaryRoutes";
import { baseHeaderMenuOptions } from "src/constants/headerMenuOptions";
import { NavigationContext } from "..";

const context: NavigationContext = {
  active: false,
  version: "v0.0.0",
  path: "",
  headerMenu: baseHeaderMenuOptions,
  breadcrumb: {
    list: [],
    pathNameReplace: [],
  },
  primary: {
    posY: 0,
    pongActive: false,
    active: {
      id: "projects",
      labelKey: "projects",
      href: "/projects",
    },
    list: basePrimaryRoutes,
  },
  // Dynamically updated label and project title
  secondary: {
    posY: 0,
    pongActive: false,
    ready: false,
    root: null,
    list: [],
  },
};

export default context;
