import { useActor } from "@xstate/react";
import { Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Brand from "src/components/Brand";
import NavPrimary from "src/components/NavPrimary";
import NavSecondary from "src/components/NavSecondary";

import { Base, BaseFooter, BaseHeader, BaseBody, CloseButton } from "./styles";
import { NavigationStateContextType } from "src/contexts/NavigationContext";
import { NAVIGATION_TAGS } from "src/machines/navigationMachine";
import { useApp } from "src/contexts/AppContext";
import { useTranslation } from "react-i18next";
import { COMMON } from "src/i18n/constants/namespaces";

interface NavigationProps {
  actor: NavigationStateContextType;
}

const Navigation = ({ actor }: NavigationProps) => {
  const { t: getTranslation } = useTranslation([COMMON]);
  const [state, send] = useActor(actor);

  const { appService } = useApp();
  const [appState] = useActor(appService);

  return (
    <Base>
      <BaseHeader>
        <CloseButton onClick={() => send({ type: "INACTIVE" })} color="primary">
          <CloseIcon fontSize="medium" />
        </CloseButton>
        <Brand onClick={() => send({ type: "INACTIVE" })} />
      </BaseHeader>
      <BaseBody>
        {state.hasTag(NAVIGATION_TAGS.PRIMARY) && (
          <NavPrimary
            actor={actor}
            onNavSelect={() => send({ type: "INACTIVE" })}
          />
        )}
        {state.hasTag(NAVIGATION_TAGS.SECONDARY) && (
          <NavSecondary
            actor={actor}
            onNavSelect={() => send({ type: "INACTIVE" })}
          />
        )}
      </BaseBody>
      <BaseFooter>
        <Link
          href={process.env.NEXT_PUBLIC_CHANGE_LOG_URL}
          target="_blank"
          underline="always"
        >
          <Typography variant="h6">
            {getTranslation("versionHistory", {
              ns: COMMON,
            })}
          </Typography>
        </Link>

        {appState.context.paymentStatus != null &&
          !appState.context.paymentStatus.paymentExempt &&
          appState.context.paymentStatus.isTrial &&
          appState.context.paymentStatus.isActive && (
            <Typography variant="h6">
              {getTranslation("freeTrialDaysLeft", {
                count: appState.context.paymentStatus.daysLeftInTrial ?? 0,
                ns: COMMON,
              })}
            </Typography>
          )}
      </BaseFooter>
    </Base>
  );
};

export default Navigation;
