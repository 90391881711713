import styled from "@emotion/styled";
import { alpha } from "@mui/material";
import { motion } from "framer-motion";

interface BaseProps {
  active?: boolean;
}

export const Base = styled.div<BaseProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => alpha(theme.palette.primary.dark, 0.95)};
  z-index: 2;
  opacity: ${({ active }) => (active ? 1 : 0)};
  visibility: ${({ active }) => (active ? "visible" : "hidden")};
  pointer-events: ${({ active }) => (!active ? "none" : "auto")};
  transition-property: opacity, visibility;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

export const BaseContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
