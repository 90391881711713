import { assign } from "xstate";
import type { AppContext, AppEvent } from "..";

// Debug
export const log = (ctx: AppContext, evt: AppEvent) =>
  console.log("APP CONTEXT", ctx, evt);

export const setPaymentStatus = assign<AppContext, AppEvent>({
  paymentStatus: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-payment-status") return ctx.paymentStatus;
    return evt.data;
  },
});

export const clearPaymentStatus = assign<AppContext, AppEvent>({
  paymentStatus: () => null,
});

/********************************************** */
// Access token set and removal
/********************************************** */

export const setAccessToken = assign<AppContext, AppEvent>({
  auth: ({ auth }, evt) => {
    if (evt.type !== "AUTH_AUTHENTICATED") return auth;

    localStorage.setItem("altaiToken", evt.accessToken ?? "");
    return {
      ...auth,
      accessToken: evt.accessToken,
      userType: evt.userType,
      firebaseId: evt.firebaseId,
      isTeamAdmin: evt.isTeamAdmin,
      username: evt.username,
    };
  },
});

export const updateAccessToken = assign<AppContext, AppEvent>({
  auth: ({ auth }, evt) => {
    if (evt.type !== "UPDATE_ACCESS_TOKEN") return auth;
    return {
      ...auth,
      accessToken: evt.accessToken,
    };
  },
});

export const setCustonTokenUrl = assign<AppContext, AppEvent>({
  customToken: (ctx, evt) => {
    if (evt.type !== "INIT_LOGIN_WITH_CUSTOM_TOKEN") return ctx.customToken;
    return evt.customToken;
  },
});

export const clearAccessToken = assign<AppContext, AppEvent>({
  auth: ({ auth }, evt) => {
    if (
      evt.type !== "AUTH_UNAUTHENTICATED" &&
      evt.type !== "INIT_LOGIN_WITH_CUSTOM_TOKEN"
    )
      return auth;

    localStorage.setItem("altaiToken", "");
    return {
      ...auth,
      accessToken: null,
      userType: null,
      isTeamAdmin: false,
      firebaseId: null,
      username: null,
    };
  },
});

/********************************************** */
// Error code set and removal
/********************************************** */

export const setAuthErrorCode = assign<AppContext, AppEvent>({
  auth: ({ auth }, evt) => {
    if (
      evt.type !== "error.platform.auth-login" &&
      evt.type !== "error.platform.auth-login-with-custom-token"
    )
      return auth;
    return {
      ...auth,
      errorCode: evt.data.code,
    };
  },
});

export const clearAuthErrorCode = assign<AppContext, AppEvent>({
  auth: ({ auth }, evt) => {
    if (
      evt.type !== "AUTH_LOGIN" &&
      evt.type !== "AUTH_LOGIN_WITH_CUSTOM_TOKEN"
    )
      return auth;
    return {
      ...auth,
      errorCode: null,
    };
  },
});

/********************************************** */
// Reset Password
/********************************************** */

export const setForgotPasswordValues = assign<AppContext, AppEvent>({
  forgotPassword: (ctx, evt) => {
    if (evt.type !== "SUBMIT_FORGOT_PASSWORD") return ctx.forgotPassword;
    return evt.payload;
  },
});

export const setResetPasswordValues = assign<AppContext, AppEvent>({
  resetPassword: (ctx, evt) => {
    if (evt.type !== "RESET_PASSWORD") return ctx.resetPassword;
    return evt.payload;
  },
});

export const setPasswordResetUserEmail = assign<AppContext, AppEvent>({
  passwordResetUserEmail: (ctx, evt) => {
    if (evt.type !== "done.invoke.reset-password")
      return ctx.passwordResetUserEmail;
    return evt.data.id ?? "";
  },
});

export const resetRoutePath = assign<AppContext, AppEvent>({
  routerPath: (ctx, evt) => {
    if (evt.type !== "RESET_ROUTE_PATH") return ctx.routerPath;
    return "/";
  },
});

/********************************************** */
// Reset Password
/********************************************** */

export const setMessagingUserToken = assign<AppContext, AppEvent>({
  messagingUserToken: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-messaging-user-token")
      return ctx.messagingUserToken;
    return evt.data ?? "";
  },
});

export const removeMessagingUserToken = assign<AppContext, AppEvent>({
  messagingUserToken: (ctx, evt) => {
    if (evt.type !== "done.invoke.logout-message-token")
      return ctx.messagingUserToken;
    return null;
  },
});

/********************************************** */
// User Home Page Details
/********************************************** */

export const setAgencyUserHomePageDetails = assign<AppContext, AppEvent>({
  agencyUserDetails: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-agency-user-hp-details")
      return ctx.agencyUserDetails;
    return evt.data;
  },
  userDetailsLoaded: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-agency-user-hp-details")
      return ctx.userDetailsLoaded;

    if (evt.data !== null) {
      return true;
    }

    return false;
  },
  isFirstDayOnAltai: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-agency-user-hp-details")
      return ctx.isFirstDayOnAltai;

    const firstVisitDate = new Date(evt.data.firstVisitDate);
    const dateNow = new Date();

    return (
      firstVisitDate.getFullYear() === dateNow.getFullYear() &&
      firstVisitDate.getMonth() === dateNow.getMonth() &&
      (firstVisitDate.getDate() === dateNow.getDate() ||
        firstVisitDate.getDate() + 1 === dateNow.getDate())
    );
  },
  userFirstName: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-agency-user-hp-details")
      return ctx.userFirstName;
    return evt.data.firstName;
  },
  lastName: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-agency-user-hp-details")
      return ctx.lastName;
    return evt.data.lastName;
  },
});

export const setArtistHomePageDetails = assign<AppContext, AppEvent>({
  artistHomePageDetails: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-artist-hp-details")
      return ctx.artistHomePageDetails;
    return evt.data;
  },
  userDetailsLoaded: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-artist-hp-details")
      return ctx.userDetailsLoaded;

    if (evt.data !== null) {
      return true;
    }

    return false;
  },
  isFirstDayOnAltai: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-artist-hp-details")
      return ctx.isFirstDayOnAltai;

    const firstVisitDate = new Date(evt.data.firstVisitDate);
    const dateNow = new Date();

    return (
      firstVisitDate.getFullYear() === dateNow.getFullYear() &&
      firstVisitDate.getMonth() === dateNow.getMonth() &&
      (firstVisitDate.getDate() === dateNow.getDate() ||
        firstVisitDate.getDate() + 1 === dateNow.getDate())
    );
  },
  userFirstName: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-artist-hp-details")
      return ctx.userFirstName;
    return evt.data.firstName;
  },
  lastName: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-artist-hp-details") return ctx.lastName;
    return evt.data.lastName;
  },
});

export const setCdHomePageDetails = assign<AppContext, AppEvent>({
  cdHomePageDetails: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-cd-hp-details")
      return ctx.cdHomePageDetails;
    return evt.data;
  },
  userDetailsLoaded: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-cd-hp-details")
      return ctx.userDetailsLoaded;

    if (evt.data !== null) {
      return true;
    }

    return false;
  },
  isFirstDayOnAltai: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-cd-hp-details")
      return ctx.isFirstDayOnAltai;

    const firstVisitDate = new Date(evt.data.firstVisitDate);
    const dateNow = new Date();

    return (
      firstVisitDate.getFullYear() === dateNow.getFullYear() &&
      firstVisitDate.getMonth() === dateNow.getMonth() &&
      (firstVisitDate.getDate() === dateNow.getDate() ||
        firstVisitDate.getDate() + 1 === dateNow.getDate())
    );
  },
  userFirstName: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-cd-hp-details") return ctx.userFirstName;
    return evt.data.firstName;
  },
  lastName: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-cd-hp-details") return ctx.lastName;
    return evt.data.lastName;
  },
});

export const setCdCommentNotifications = assign<AppContext, AppEvent>({
  cdCommentNotifications: (ctx, evt) => {
    if (evt.type !== "done.invoke.get-cd-comment-notifications")
      return ctx.cdCommentNotifications;
    return evt.data;
  },
});

export const clearHomePageText = assign<AppContext, AppEvent>({
  artistHomePageDetails: (ctx, evt) => {
    if (
      evt.type !== "UPDATE_HOME_PAGE_TEXT" &&
      evt.type !== "done.invoke.auth-logout"
    )
      return ctx.artistHomePageDetails;
    return null;
  },
  agencyUserDetails: (ctx, evt) => {
    if (
      evt.type !== "UPDATE_HOME_PAGE_TEXT" &&
      evt.type !== "done.invoke.auth-logout"
    )
      return ctx.agencyUserDetails;
    return null;
  },
  userDetailsLoaded: (ctx, evt) => {
    if (
      evt.type !== "UPDATE_HOME_PAGE_TEXT" &&
      evt.type !== "done.invoke.auth-logout"
    )
      return ctx.userDetailsLoaded;

    return false;
  },
  isFirstDayOnAltai: (ctx, evt) => {
    if (
      evt.type !== "UPDATE_HOME_PAGE_TEXT" &&
      evt.type !== "done.invoke.auth-logout"
    )
      return ctx.isFirstDayOnAltai;

    return null;
  },
  userFirstName: (ctx, evt) => {
    if (
      evt.type !== "UPDATE_HOME_PAGE_TEXT" &&
      evt.type !== "done.invoke.auth-logout"
    )
      return ctx.userFirstName;
    return "";
  },
  lastName: (ctx, evt) => {
    if (
      evt.type !== "UPDATE_HOME_PAGE_TEXT" &&
      evt.type !== "done.invoke.auth-logout"
    )
      return ctx.lastName;
    return "";
  },
});

export const showAccountPaused = () => {
  console.log("ACTION(showAccountPaused): placeholder");
};

export const showArtistPricingTable = () => {
  console.log("ACTION(showArtistPricingTable): placeholder");
};

export const updateUserLocale = assign<AppContext, AppEvent>((ctx, evt) => {
  if (
    evt.type !== "done.invoke.update-user-locale" ||
    !evt.data ||
    evt.data.length < 2
  )
    return ctx;

  const cdHomePageDetails = ctx.cdHomePageDetails;
  const agencyUserDetails = ctx.agencyUserDetails;
  const artistHomePageDetails = ctx.artistHomePageDetails;

  if (ctx.auth.userType === "CastingDirector" && cdHomePageDetails) {
    cdHomePageDetails.defaultLocale = evt.data;
  }

  if (ctx.auth.userType === "Agent" && agencyUserDetails) {
    agencyUserDetails.defaultLocale = evt.data;
  }

  if (ctx.auth.userType === "Artist" && artistHomePageDetails) {
    artistHomePageDetails.defaultLocale = evt.data;
  }

  return {
    ...ctx,
    cdHomePageDetails: cdHomePageDetails,
    agencyUserDetails: agencyUserDetails,
    artistHomePageDetails: artistHomePageDetails,
  };
});
