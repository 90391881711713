import toLowerFirstCharacterString from "./toLowerFirstCharacterString";
import toTitleCaseString from "./toTitleCaseString";

const toI18nContextString = (value: string | null): string => {
  if (!value || value === null) return "";

  var camelCaseString = toTitleCaseString(
    value.toLowerCase().replace(/[^a-zA-Z\s]/g, " ")
  )!.replace(/\s/g, "");

  return toLowerFirstCharacterString(camelCaseString);
};

export default toI18nContextString;
