import { ReactNode } from "react";
import { useApp } from "src/contexts/AppContext";
import { Base, Brand } from "./styles";
import { useActor } from "@xstate/react";

// Description
// Manage view when determining if the user is authenticated or not authenticated.

interface UnknownStateProps {
  children: ReactNode;
}

const UnknownState = ({ children }: UnknownStateProps) => {
  const { appService } = useApp();
  const [state] = useActor(appService);

  const isUnknown = state.matches({
    authentication: { init: "unknown" },
  });

  return (
    <>
      {isUnknown && (
        <Base>
          <Brand />
        </Base>
      )}
      {!isUnknown && <>{children}</>}
    </>
  );
};

export default UnknownState;
