import { StreamItem, StreamItemDetail } from "..";

// NOTE - Requires other verbs to help define messaging
// NOTE - This is temporary messages, will need to confirm
export const tolinkTarget = (verb: StreamItem["verb"]): string => {
  switch (verb) {
    case "client_commented_on_artist_tape_notification":
    case "client-commented-on-artist-list-notification":
      return `_blank`;
    default: {
      return "_self";
    }
  }
};
