import { createMachine } from "xstate";
import { UserType } from "../appMachine";
import * as services from "./services";
import context from "./context";
import * as actions from "./actions";

export type NotificationEvents = {
  type: "MARK_ALL_AS_READ";
};

export interface NotificationContext {
  userType: UserType;
}

export const NOTIFICATION_TAGS = {
  LOADING: "loading",
};

const machine = createMachine<NotificationContext, NotificationEvents>(
  {
    id: "notification",
    context,
    initial: "idle",
    states: {
      markAllMessageAsRead: {
        invoke: {
          id: "mark-all-message-as-read",
          src: "markAllMessageAsRead",
          onDone: { target: "idle", actions: ["notifyMarkAllReadComplete"] },
          onError: "serverError",
        },
      },
      idle: {
        on: {
          MARK_ALL_AS_READ: "markAllMessageAsRead",
        },
      },
      serverError: {},
    },
  },
  { actions, services }
);

export default machine;
