import { AutocompleteItem } from "src/components/Autocomplete";
import TeamMember from "src/services/altai/entities/TeamMember";

const toTeamMemberItem = (value: TeamMember): AutocompleteItem => ({
  id: value.id,
  key: `${value.firstName} ${value.lastName}`,
  value: value.id,
});

export default toTeamMemberItem;
