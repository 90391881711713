import { Global, css, Theme } from "@emotion/react";
import { alpha } from "@mui/material";
import space from "src/utils/space";

// Basic global styles
const styles = (theme: Theme) => {
  const { palette } = theme;
  // console.log(theme);
  return css`
    html {
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    html,
    body {
      position: relative;
      margin: 0;
      padding: 0;
    }

    body {
      font-size: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: ${palette.background.default};
      color: ${palette.text.primary};
    }

    * {
      scrollbar-color: ${alpha(palette.secondary.main, 0.4)}
        ${alpha(palette.primary.light, 0.1)};
    }

    body::-webkit-scrollbar, .MuiDialogContent-root::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
    }
    body::-webkit-scrollbar-thumb,  .MuiDialogContent-root::-webkit-scrollbar-thumb {
      background-color: ${alpha(palette.secondary.main, 0.4)};
    }
    body::-webkit-scrollbar-track, .MuiDialogContent-root::-webkit-scrollbar-track {
      background-color: ${alpha(palette.primary.light, 0.1)};
    }

    /* Fix for Firefox autofocus on disabled elements */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: ${palette.text.primary};
      -webkit-box-shadow: 0 0 0px 1000px #000 inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    /* Override date picker, sits outside of theme customisation */
    .MuiDateRangePickerDay-day:not(.Mui-selected) {
      border-color: ${alpha(palette.text.primary, 0.5)}!important;
    }
  `;
};

const GlobalStyles = () => <Global styles={styles} />;

export default GlobalStyles;
