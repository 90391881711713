import { dropRepeats } from "ramda";
import { NavItem } from "src/types/NavItem";
import isMatchPath from "src/utils/isMatchPath";
import { assign } from "xstate";
import { NavigationContext, NavigationEvent } from "..";
import toBreadcrumb from "../utils/toBreadcrumb";
import toReplacePathBreadcrumb from "../utils/toReplacePathBreadcrumb";

/********************************************** */
// Helpers
/********************************************** */

export const log = (context: NavigationContext, event: NavigationEvent) =>
  console.log(context, event);

/********************************************** */
// Set nav item active
/********************************************** */

export const setActive = assign<NavigationContext, NavigationEvent>({
  active: (ctx, evt) => {
    if (evt.type !== "ACTIVE") return ctx.active;
    return true;
  },
});

export const setActiveRoute = assign<NavigationContext, NavigationEvent>({
  primary: (ctx, evt) => {
    if (evt.type !== "SET_ACTIVE_ROUTE") return ctx.primary;
    return {
      ...ctx.primary,
      active: evt.active,
    };
  },
});

export const setPrimaryNav = assign<NavigationContext, NavigationEvent>({
  primary: (ctx, evt) => {
    if (evt.type !== "SET_PRIMARY_NAV") return ctx.primary;
    return {
      ...ctx.primary,
      list: evt.list,
    };
  },
  headerMenu: (ctx, evt) => {
    if (evt.type !== "SET_PRIMARY_NAV") return ctx.headerMenu;
    return evt.menuOptions;
  },
});

/********************************************** */
// Set nav item inactive
/********************************************** */

export const setInactive = assign<NavigationContext, NavigationEvent>({
  active: (ctx, evt) => {
    if (evt.type !== "INACTIVE") return ctx.active;
    return false;
  },
});

/********************************************** */
// set position
/********************************************** */

export const setPosition = assign<NavigationContext, NavigationEvent>(
  (ctx, evt, actor) => {
    if (evt.type !== "PONG_POSITION_UPDATE") return ctx;

    // Determine if primary or secondary to associate active correctly
    const isPrimary = actor.state?.matches("primary");
    if (isPrimary) {
      return {
        ...ctx,
        primary: {
          ...ctx.primary,
          posY: evt.pos,
        },
      };
    }
    return {
      ...ctx,
      secondary: {
        ...ctx.secondary,
        posY: evt.pos,
      },
    };
  }
);

/********************************************** */
// Set pong active
/********************************************** */

export const setPongActive = assign<NavigationContext, NavigationEvent>(
  (ctx, evt, actor) => {
    if (evt.type !== "PONG_ACTIVE_POSITION") return ctx;
    // Determine if primary or secondary to associate active correctly
    const isPrimary = actor.state?.matches("primary");

    console.log("-------------------Error investigate---------------------");
    console.log("Navigation Machine:", JSON.stringify(actor.state));
    console.log("Is Promary navigation:", isPrimary);

    const list = isPrimary ? ctx.primary.list : ctx.secondary.list;

    console.log("Navigation List:", JSON.stringify(list));
    console.log("Event path:", evt.path);

    const activeItem = list.find((v) => isMatchPath(evt.path, v.href));

    console.log("Active Item:", JSON.stringify(activeItem));

    const pongActive = typeof activeItem !== "undefined";

    console.log("PongActive:", pongActive);
    console.log("Secondary:", JSON.stringify(ctx.secondary));
    console.log("Primary:", JSON.stringify(ctx.primary));
    console.log("-------------------End error investigate-------------------");

    if (isPrimary) {
      return {
        ...ctx,
        primary: {
          ...ctx.primary,
          pongActive,
        },
      };
    }
    return {
      ...ctx,
      secondary: {
        ...ctx.secondary,
        pongActive,
      },
    };
  }
);

/********************************************** */
// update current path
/********************************************** */

export const setPath = assign<NavigationContext, NavigationEvent>({
  path: (ctx, evt) => {
    if (evt.type !== "UPDATE") {
      return ctx.path;
    }
    return evt.path;
  },
});

/********************************************** */
// Secondary nav ready state
/********************************************** */

export const setSecondaryNavReady = assign<NavigationContext, NavigationEvent>({
  secondary: (ctx) => {
    return {
      ...ctx.secondary,
      ready: true,
    };
  },
});

export const setSecondaryNavNotReady = assign<
  NavigationContext,
  NavigationEvent
>({
  secondary: (ctx) => {
    return {
      ...ctx.secondary,
      ready: false,
    };
  },
});

/********************************************** */
// Generate breadcrumb
/********************************************** */

export const setBreadcrumb = assign<NavigationContext, NavigationEvent>({
  breadcrumb: (ctx, evt) => {
    // Breakdown paths
    const splitPath = ctx.path.split("/");
    // Remove all duplicates, generally this will be empty strings
    const cleanList = dropRepeats(splitPath);

    // TODO - TEMP while routes are still being figured out, drop "In Person"
    // from the breadcrumb list
    const noInPerson = cleanList.filter((p) => p.toLowerCase() !== "in-person");

    // Gnerate nav items based on router path
    const list = noInPerson.reduce(toBreadcrumb, []) as NavItem[];
    // Run through and replace
    const breadcrumbReplace = ctx.breadcrumb.pathNameReplace;
    // Loop through list and replace if needed
    const listReplace = list.map(toReplacePathBreadcrumb(breadcrumbReplace));
    //
    return {
      ...ctx.breadcrumb,
      list: listReplace,
    };
  },
});

export const updateBreadcrumb = assign<NavigationContext, NavigationEvent>({
  breadcrumb: (ctx, evt) => {
    if (evt.type !== "UPDATE_BREADCRUMB") return ctx.breadcrumb;
    // Get the current breadcrumb list
    const list = ctx.breadcrumb.list;
    // Run through and replace
    const breadcrumbReplace = evt.breadcrumbReplace;
    // Loop through list and replace if needed
    const listReplace = list.map(toReplacePathBreadcrumb(breadcrumbReplace));
    //
    return {
      ...ctx.breadcrumb,
      list: listReplace,
    };
  },
});

/********************************************** */
// Generate secondary navigation
/********************************************** */

export const setSecondaryNav = assign<NavigationContext, NavigationEvent>({
  breadcrumb: (ctx, evt) => {
    if (evt.type !== "SECONDARY_NAV_READY") return ctx.breadcrumb;
    return {
      ...ctx.breadcrumb,
      pathNameReplace: evt.breadcrumbReplace,
    };
  },
  secondary: (ctx, evt) => {
    if (evt.type !== "SECONDARY_NAV_READY") return ctx.secondary;
    return {
      ...ctx.secondary,
      root: evt.root,
      list: evt.list,
    };
  },
});

export const clearSecondaryNav = assign<NavigationContext, NavigationEvent>({
  secondary: (ctx) => {
    return {
      ...ctx.secondary,
      root: null,
      list: [],
    };
  },
});

export const notifyPrimary = () => {
  console.log("ACTION(notifyPrimary): placeholder");
};

export const notifyHeaderMenu = () => {
  console.log("ACTION(notifyHeaderMenu): placeholder");
};
