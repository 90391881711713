import { ReactNode, useContext, createContext } from "react";
import WebSocketManageSessionService from "src/services/manageSessions/WebSocketManageSessionService";

/********************************************** */
// Context provider
/********************************************** */

interface WebSocketManageSessionProviderProps {
  children: ReactNode;
}

const WebSocketContext = createContext<WebSocketManageSessionService | null>(
  null
);

const path = `${process.env.NEXT_PUBLIC_ALTAI_ROLES_API_URL}roles/managesessionshub?sessionId=[sessionId]`;
const service = new WebSocketManageSessionService(path);

const WebSocketManageSessionProvider = ({
  children,
}: WebSocketManageSessionProviderProps) => {
  return (
    <WebSocketContext.Provider value={service}>
      {children}
    </WebSocketContext.Provider>
  );
};

const useWebSocketManageSession = () => {
  const context = useContext(WebSocketContext);
  if (typeof context === "undefined") {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

export { useWebSocketManageSession, WebSocketManageSessionProvider };
