import {
  Grow,
  IconButton,
  MenuItem,
  Paper,
  ClickAwayListener,
} from "@mui/material";
import { useRef, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Base, BaseMenu, BasePopper } from "./styles";
import { useTranslation } from "react-i18next";
import { COMMON } from "src/i18n/constants/namespaces";

const defaultOptions = [
  { id: "account", labelKey: "account" },
  { id: "my-profile", labelKey: "myProfile", disabled: true },
  { id: "support", labelKey: "support", disabled: true },
  { id: "help", labelKey: "help", disabled: true },
  { id: "profile", labelKey: "profilePreview" },
  { id: "sign-out", labelKey: "signOut" },
] as MenuOption[];

export interface MenuOption {
  id: string;
  labelKey: string;
  disabled?: boolean;
}

interface HeaderBarProps {
  options?: MenuOption[];
  onChange: (option: MenuOption["id"]) => void;
}

const HeaderBarMenu = ({
  options = defaultOptions,
  onChange,
}: HeaderBarProps) => {
  const { t: getTranslation } = useTranslation([COMMON]);
  const ref = useRef<HTMLButtonElement>(null);
  const [active, setActive] = useState<boolean>(false);

  //
  const onSelectHandler = (id: MenuOption["id"]) => {
    onChange(id);
    setActive(false);
  };

  return (
    <>
      <Base>
        <IconButton ref={ref} onClick={() => setActive(true)}>
          <AccountCircleIcon />
        </IconButton>
      </Base>
      <BasePopper
        open={active}
        anchorEl={ref.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "top right",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setActive(false)}>
                  <BaseMenu>
                    {options!.map((option) => (
                      <MenuItem
                        disabled={option.disabled}
                        key={option.id}
                        onClick={() => onSelectHandler(option.id)}
                      >
                        {getTranslation(`headerMenu.${option.labelKey}`, {
                          ns: COMMON,
                        })}
                      </MenuItem>
                    ))}
                  </BaseMenu>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </BasePopper>
    </>
  );
};

export default HeaderBarMenu;
