import { format } from "date-fns";
import moment from "moment-timezone";

const formatSelftapeDueTime = (time: Date, timezone: string): string => {
  const formatted = moment
    .tz(format(time, "yyyy-MM-dd HH:mm"), timezone)
    .format("HH:mm");
  return formatted;
};

export default formatSelftapeDueTime;
