import { MenuOption } from "src/components/HeaderBarMenu";

export const baseHeaderMenuOptions: MenuOption[] = [
  { id: "account", labelKey: "account" },
  { id: "support", labelKey: "support", disabled: true },
  { id: "sign-out", labelKey: "signOut" },
];

export const authenticatedHeaderMenuOptions: MenuOption[] = [
  { id: "account", labelKey: "account" },
  { id: "support", labelKey: "support" },
  { id: "sign-out", labelKey: "signOut" },
];
