import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Base, BaseImage, BaseInner } from "./styles";

// TODO - add dynamic images
const STATIC_BACKGROUND = ["/background/altai-static-background.JPEG"];

const customImageLoader = ({ src }: { src: string }) => {
  return `${src}`;
};

function randomRange(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const LayoutBackground = () => {
  const router = useRouter();
  const [src, setSrc] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  // Determine src
  useEffect(() => {
    const index = randomRange(0, STATIC_BACKGROUND.length - 1);
    setSrc(STATIC_BACKGROUND[index]);
  }, []);

  // Manage load state
  useEffect(() => {
    const path = router.asPath;
    setActive(path === "/" && loaded);
  }, [router, loaded]);

  return (
    <Base>
      <BaseInner active={active}>
        {src !== null && (
          <BaseImage
            unoptimized
            priority
            loader={customImageLoader}
            onLoad={() => setLoaded(true)}
            src={src}
            objectFit="cover"
            layout="fill"
            alt=""
          />
        )}
      </BaseInner>
    </Base>
  );
};

export default LayoutBackground;
