import styled from "@emotion/styled";
import { Typography, Link, alpha } from "@mui/material";
import {
  NewActivitiesNotification,
  NotificationDropdown,
} from "react-activity-feed";

import em from "src/utils/em";
import space from "src/utils/space";

export const Base = styled.div`
  position: relative;
  width: ${em(40)};
  display: flex;
  margin-top: ${em(2.5)};
`;

// Overrides
export const BaseNotifier = styled(NewActivitiesNotification)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  box-shadow: none;
  font-size: ${em(12)};

  .raf-link {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-family: ${({ theme }) => theme.typography.button.fontFamily};
  }
`;

export const BaseDropdown = styled(NotificationDropdown)`
  .raf-icon-badge {
    width: ${em(40)};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .raf-dropdown-panel--arrow-right:after {
    display: none;
  }

  .raf-dropdown-panel {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    border-radius: ${em(4)};
  }

  .raf-notification-dropdown__panel {
    top: ${em(40)};
  }

  .raf-dropdown-panel__header {
    position: relative;
  }

  .raf-dropdown-panel__content {
    position: relative;
    padding: ${space(2)} ${space(0)};
  }

  .raf-load-more-button {
    position: relative;
    padding: ${space(1)} ${space()};
  }

  .raf-button {
    width: 100%;
    margin: 0;
    padding: ${space(2)} ${space()};
    height: ${em(60)};
    border-radius: ${space(4)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.primary.dark};
    background-color: ${({ theme }) => theme.palette.secondary.main};
    font-family: ${({ theme }) => theme.typography.button.fontFamily};
    font-size: ${em(12)};
  }

  .rfu-loading-indicator__spinner {
    border-color: ${({ theme }) =>
      `${alpha(theme.palette.primary.dark, 0.4)} ${alpha(
        theme.palette.primary.dark,
        0.1
      )} ${alpha(theme.palette.primary.dark, 0.1)}`}!important;
  }
`;

export const BasePrimaryTitle = styled(Typography)``;

export const BaseHeaderLink = styled(Link)`
  position: relative;
  display: flex;
  width: 100%;
  padding: ${space(2)};
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const BasePlaceHolder = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: ${space(2)};
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const BaseIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transform: scale(1);
  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;
