const toCapitalise = (v: string): string => {
  if (v.length === 0) return "";
  const head = v[0].toUpperCase();
  const body = v.substring(1, v.length);
  return `${head}${body}`;
};

const toNavPathName = (v: string): string => {
  const split = v.split("-");
  const format = split.map(toCapitalise);
  return format.join(" ");
};

export default toNavPathName;
