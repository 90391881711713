import * as yup from "yup";

export interface ResetPasswordProps {
  resetToken: string;
  newPassword: string;
  confirmPassword: string;
}

export const initialValues: ResetPasswordProps = {
  resetToken: "",
  newPassword: "",
  confirmPassword: "",
};

const schema = yup.object({
  newPassword: yup
    .string()
    .min(8, "passwordMustBeEightChacters")
    .required("passwordRequired")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,99}$/,
      "passwordDoesNotMeetRequirements"
    ),
  confirmPassword: yup
    .string()
    .required("passwordConfirmationRequired")
    .oneOf([yup.ref("newPassword"), null], "passwordConfirmationMustMatch"),
});

export default schema;
