import { useRef } from "react";
import { useActor } from "@xstate/react";
import { motion } from "framer-motion";
import Link from "next/link";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavLink from "src/components/NavLink";
import {
  Base,
  BaseHeader,
  BaseBody,
  IconButton,
  BaseList,
  Pong,
  Title,
} from "./styles";
import { NavigationStateContextType } from "src/contexts/NavigationContext";
import SkeletonLoader from "src/components/SkeletonLoader";
import generateList from "src/utils/generateList";
import { NavItem } from "src/types/NavItem";
import { useTranslation } from "react-i18next";
import { COMMON } from "src/i18n/constants/namespaces";

interface NavSecondaryProps {
  actor: NavigationStateContextType;
  onNavSelect: () => void;
}

const NavSecondary = ({ onNavSelect, actor }: NavSecondaryProps) => {
  const { t: getTranslation } = useTranslation([COMMON]);
  const baseRef = useRef<HTMLDivElement>(null);
  const [state, send] = useActor(actor);

  const {
    context: { secondary, primary },
  } = state;

  // Handler to determine view values to update state
  const onUpdateHandler = (posY: number) => {
    const offset = 11;
    const parentY = baseRef.current!.getBoundingClientRect().top;
    send({ type: "PONG_POSITION_UPDATE", pos: posY - parentY + offset });
  };

  // Generate list of nav items in loading state (skeleton)
  const list = !secondary.ready
    ? (generateList({}, 3) as NavItem[])
    : secondary.list;

  if (typeof primary.active.href === 'undefined') {
    // This is expected to be populated
    console.error('navigation::primary::undefined::href', primary);
  }
  if (typeof secondary.root?.href === 'undefined') {
    // Note this may not be populated
    console.info('navigation::secondary::undefined::href', secondary)
  }

  return (
    <Base>
      <BaseHeader>
        <motion.div
          initial={{ x: 16, opacity: 0 }}
          transition={{ type: "spring", duration: 0.5 }}
          animate={{ x: 0, opacity: 1 }}
        >
          <Link href={primary.active?.href} legacyBehavior>
            <IconButton color="primary">
              <ArrowBackIcon fontSize="medium" />
            </IconButton>
          </Link>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          transition={{ type: "spring", duration: 0.5 }}
          animate={{ opacity: 1 }}
        >
          <SkeletonLoader height={35} loading={!secondary.ready}>
            <Link href={secondary.root?.href ?? ""} legacyBehavior>
              <Title onClick={() => onNavSelect()}>
                {secondary.root?.labelKey
                  ? getTranslation(`navigation.${secondary.root?.labelKey}`, {
                    pathName: secondary.root?.pathLabel,
                    ns: COMMON,
                    defaultValue: secondary.root?.pathLabel,
                  })
                  : ""}
              </Title>
            </Link>
          </SkeletonLoader>
        </motion.div>
      </BaseHeader>
      <motion.div
        initial={{ opacity: 0 }}
        transition={{ type: "spring", duration: 1 }}
        animate={{ opacity: 1 }}
      >
        <BaseBody ref={baseRef}>
          <Pong
            animate={{
              y: secondary.posY,
              opacity: secondary.pongActive ? 1 : 0,
            }}
            transition={{ type: "spring", duration: 0.4 }}
          />
          <BaseList>
            {list.map((item) => (
              <li key={item.id}>
                <SkeletonLoader
                  height={50}
                  width={100}
                  loading={!secondary.ready}
                >
                  <NavLink
                    variant="secondary"
                    onUpdate={onUpdateHandler}
                    onClick={onNavSelect}
                    href={item?.href}
                  >
                    {item.labelKey
                      ? getTranslation(`navigation.${item.labelKey}`, {
                        pathName: item.pathLabel,
                        ns: COMMON,
                        defaultValue: item.pathLabel,
                      })
                      : ""}
                  </NavLink>
                </SkeletonLoader>
              </li>
            ))}
          </BaseList>
        </BaseBody>
      </motion.div>
    </Base>
  );
};

export default NavSecondary;
