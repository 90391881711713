import { NavItem } from "src/types/NavItem";

const agencyPrimaryRoutes: NavItem[] = [
  {
    id: "casting-briefs",
    labelKey: "castingBriefs",
    href: "/casting-briefs",
  },
  {
    id: "status-report",
    labelKey: "statusReport",
    href: "/status-report",
  },
  {
    id: "avail-checks",
    labelKey: "availChecks",
    href: "/avail-checks",
  },
  {
    id: "calendar",
    labelKey: "calendar",
    href: "/calendar",
  },
  {
    id: "roster",
    labelKey: "roster",
    href: "/artist-roster",
  },
  {
    id: "directory",
    labelKey: "directory",
    href: "/directory",
  },
];

export default agencyPrimaryRoutes;
