import { StreamItem, StreamItemDetail } from "..";

const toStreamItem = (activityGroup: any): StreamItem | null => {
  const { object } = activityGroup.activities[0];
  try {
    const streamItem = JSON.parse(object) as StreamItemDetail;
    return {
      id: activityGroup.id,
      isRead: activityGroup.is_read,
      isSeen: activityGroup.is_seen,
      verb: activityGroup.verb,
      createdAt: activityGroup.created_at,
      detail: streamItem,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default toStreamItem;
