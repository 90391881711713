import styled from "@emotion/styled";
import Image from "next/legacy/image";

export const Base = styled.div`
  position: absolute;
  /* Offset for navigation bar */
  top: 4em;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

interface BaseInnerProps {
  active?: boolean;
}

export const BaseInner = styled.div<BaseInnerProps>`
  position: relative;
  width: 100%;
  height: 100%;
  opacity: ${({ active }) => (active ? "1.0" : "0")};
  transform: ${({ active }) => (active ? "scale(1.0)" : "scale(1.1)")};
  transition-property: opacity, transform;
  transition-duration: ${({ active }) => (active ? "3s" : "0.5s")};
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

export const BaseImage = styled(Image)`
  filter: opacity(0.5);
`;
