import { AxiosRequestConfig, CancelTokenSource } from "axios";
import { HTTPAnonymousService } from "./httpAnonymousService";
import { HTTPBaseService, ServiceVariant } from "./httpBaseService";

/********************************************** */
// axios file upload config
/********************************************** */

const toUploadConfig = (
  onUploadProgress: (e: ProgressEvent) => void,
  cancelSource?: CancelTokenSource
): AxiosRequestConfig<FormData> => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
    cancelToken: cancelSource?.token,
  };
};

/********************************************** */
// GET
/********************************************** */

interface GetProps extends ServiceVariant {
  path: string;
}

export const get = ({ path, variant }: GetProps): Promise<any> => {
  const request = new HTTPBaseService().toRequest(variant);
  return request.get(path);
};

export const getAnonymous = ({ path, variant }: GetProps): Promise<any> => {
  const request = new HTTPAnonymousService().toRequest(variant);
  return request.get(path);
};

export const getIpApi = (path: string): Promise<any> => {
  const ipApiRequest = new HTTPBaseService().toRequest("ipApi");
  return ipApiRequest.get(path);
};

export const getIpInfo = (path: string): Promise<any> => {
  const ipInfoRequest = new HTTPBaseService().toRequest("ipInfo");
  return ipInfoRequest.get(path);
};

/********************************************** */
// POST
/********************************************** */

interface PostProps extends ServiceVariant {
  path: string;
  data?: any;
}

export const post = ({ path, data, variant }: PostProps): Promise<any> => {
  const request = new HTTPBaseService().toRequest(variant);
  return request.post(path, data);
};

export const postAnonymous = ({
  path,
  data,
  variant,
}: PostProps): Promise<any> => {
  const request = new HTTPAnonymousService().toRequest(variant);
  return request.post(path, data);
};

/********************************************** */
// DELETE
/********************************************** */

interface DeleteProps extends ServiceVariant {
  path: string;
}

export const deleteFile = ({ path, variant }: DeleteProps): Promise<any> => {
  const request = new HTTPBaseService().toRequest(variant);
  return request.delete(path);
};

export const deleteFileAnonymous = ({
  path,
  variant,
}: DeleteProps): Promise<any> => {
  const request = new HTTPAnonymousService().toRequest(variant);
  return request.delete(path);
};

/********************************************** */
// UPLOAD FILE
/********************************************** */
interface UploadFileProps extends ServiceVariant {
  path: string;
  file: File;
  onProgress: (percent: number) => void;
  cancelSource?: CancelTokenSource;
}

export const uploadFile = ({
  path,
  file,
  onProgress,
  cancelSource,
  variant,
}: UploadFileProps): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);
  const request = new HTTPBaseService().toRequest(variant);
  return request.post(
    path,
    formData,
    toUploadConfig((e: ProgressEvent) => {
      const percentage = Math.round((e.loaded * 100) / e.total);
      onProgress(percentage);
    }, cancelSource)
  );
};

export const uploadFileAnonymous = ({
  path,
  file,
  onProgress,
  cancelSource,
  variant,
}: UploadFileProps): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);
  const request = new HTTPAnonymousService().toRequest(variant);
  return request.post(
    path,
    formData,
    toUploadConfig((e: ProgressEvent) => {
      const percentage = Math.round((e.loaded * 100) / e.total);
      onProgress(percentage);
    }, cancelSource)
  );
};

/********************************************** */
// PATCH
/********************************************** */

interface PatchProps extends ServiceVariant {
  path: string;
  data?: any;
}

export const patch = ({ path, data, variant }: PostProps): Promise<any> => {
  const request = new HTTPBaseService().toRequest(variant);
  return request.patch(path, data);
};

/********************************************** */
// PUT
/********************************************** */

interface PutProps extends ServiceVariant {
  path: string;
  data?: any;
}

export const put = ({ path, data, variant }: PutProps): Promise<any> => {
  const request = new HTTPBaseService().toRequest(variant);
  return request.put(path, data);
};

export const putAnonymous = ({
  path,
  data,
  variant,
}: PutProps): Promise<any> => {
  const request = new HTTPAnonymousService().toRequest(variant);
  return request.put(path, data);
};
