import { AxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";
import firebase from "../firebase";
import { HTTPAnonymousService } from "./httpAnonymousService";

// TODO - this will no longer be necessary once we have all our microservices behind one load-balancer
export interface ServiceVariant {
  variant:
    | "projects"
    | "roles"
    | "directory"
    | "users"
    | "external"
    | "ipApi"
    | "ipInfo";
}

export class HTTPBaseService extends HTTPAnonymousService {
  private accessToken: string = "";
  private refreshingToken: Promise<string> | null = null;

  public constructor() {
    super();
    this.accessToken = localStorage.getItem("altaiToken") ?? "";
    this.initializeRequestInterceptors();
    this.initializeResponseInterceptor();
  }

  private handleRequest = (config: AxiosRequestConfig) => {
    config.headers!["Authorization"] = `Bearer ${this.accessToken}`;
    return config;
  };

  private initializeRequestInterceptors = () => {
    this.projectsRequest.interceptors.request.use(this.handleRequest);
    this.rolesRequest.interceptors.request.use(this.handleRequest);
    this.usersRequest.interceptors.request.use(this.handleRequest);
    this.directoryRequest.interceptors.request.use(this.handleRequest);
    this.externalRequest.interceptors.request.use(this.handleRequest);
  };

  private initializeResponseInterceptor = () => {
    /********************************************** */
    // Project API Response Interceptor
    /********************************************** */
    this.projectsRequest.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: any) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            this.refreshingToken = this.refreshingToken
              ? this.refreshingToken
              : this.refreshToken();
            const token = await this.refreshingToken;
            if (token !== "") {
              localStorage.setItem("altaiToken", token);
              this.accessToken = token;

              return this.projectsRequest(originalRequest);
            }
          } catch (err) {
            return Promise.reject(err);
          }
        }
      }
    );

    /********************************************** */
    // Role API Response Interceptor
    /********************************************** */
    this.rolesRequest.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: any) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            this.refreshingToken = this.refreshingToken
              ? this.refreshingToken
              : this.refreshToken();
            const token = await this.refreshingToken;
            if (token !== "") {
              localStorage.setItem("altaiToken", token);
              this.accessToken = token;

              return this.rolesRequest(originalRequest);
            }
          } catch (err) {
            return Promise.reject(err);
          }
        }
      }
    );

    /********************************************** */
    // Directory API Response Interceptor
    /********************************************** */
    this.directoryRequest.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: any) => {
        const originalRequest = error.config;
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          try {
            this.refreshingToken = this.refreshingToken
              ? this.refreshingToken
              : this.refreshToken();
            const token = await this.refreshingToken;
            if (token && token !== "") {
              localStorage.setItem("altaiToken", token);
              this.accessToken = token;

              return this.directoryRequest(originalRequest);
            }
          } catch (err) {
            return Promise.reject(err);
          }
        }
      }
    );

    /********************************************** */
    // User API Response Interceptor
    /********************************************** */
    this.usersRequest.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: any) => {
        const originalRequest = error.config;
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          try {
            this.refreshingToken = this.refreshingToken
              ? this.refreshingToken
              : this.refreshToken();
            const token = await this.refreshingToken;
            if (token !== "") {
              localStorage.setItem("altaiToken", token);
              this.accessToken = token;

              return this.usersRequest(originalRequest);
            }
          } catch (err) {
            return Promise.reject(err);
          }
        }
      }
    );

    /********************************************** */
    // External API Response Interceptor
    /********************************************** */
    this.externalRequest.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: any) => {
        const originalRequest = error.config;
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          try {
            this.refreshingToken = this.refreshingToken
              ? this.refreshingToken
              : this.refreshToken();
            const token = await this.refreshingToken;
            if (token && token !== "") {
              localStorage.setItem("altaiToken", token);
              this.accessToken = token;

              return this.externalRequest(originalRequest);
            }
          } catch (err) {
            return Promise.reject(err);
          }
        }
      }
    );
  };

  private async refreshToken(): Promise<string> {
    const app = firebase();
    const auth = getAuth(app);
    if (auth.currentUser) {
      const userData = await auth.currentUser.getIdTokenResult();
      return userData.token;
    }

    return "";
  }
}
